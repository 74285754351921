import { GridCellEditCommitParams, MuiEvent } from '@mui/x-data-grid-pro';
import toast from 'features/toast';
import { t } from 'i18next';

export const onEditCellCallbackProvider =
  // FIXME: do not call service if value still the same
  (service: any) => (params: GridCellEditCommitParams, event: MuiEvent) => {
    if (params.id) {
      const payload = {};
      payload[params.field] = params.value;
      if (payload[params.field])
        service.update(+params.id, { ...payload }).then(() => {
          toast.success(t('toasts.updated-cell'));
        });
    }
  };
