import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

const ReactFinalForm = ({ onSubmit, edit, children, hideControls, formId }: any) => {
  const { t } = useTranslation();
  const FORM_ID = formId ? formId : 'form-' + dayjs().toISOString();
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form, submitting, pristine, values, invalid, errors }) => {
        return (
          <form onSubmit={handleSubmit} id={FORM_ID}>
            <div>{children}</div>
            {!hideControls && (
              <div className="mt-4 flex justify-between">
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  onClick={form.reset}
                  disabled={submitting || pristine}>
                  {t('actions.reset')}
                </Button>
                <Button
                  form={FORM_ID}
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={submitting || pristine || invalid}>
                  {edit ? t('actions.save') : t('actions.insert')}
                </Button>
              </div>
            )}
          </form>
        );
      }}
    />
  );
};

export default ReactFinalForm;
